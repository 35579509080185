import { Pipe, PipeTransform } from '@angular/core';
import { FormControl, AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';

@Pipe({
  name: 'validator'
})
export class ValidatorPipe implements PipeTransform {

  aControles: any = {
    Nombres: 'Nombres',
    Apellido: 'Apellidos',
    Telefono: 'Teléfono',
    Correo: 'Correo electrónico',
    Servicio: 'Servicio'
  };

  transform(Errors, control: any): any {
    const error: AllValidationErrors = getFormValidationErrors(control).shift();
    console.log(error.control_name);
    let text;
    if (error) {
      switch (error.error_name) {
        case 'required': text = `"${this.aControles[error.control_name]}" es requerido!`; break;
        case 'pattern': text = `"${this.aControles[error.control_name]}" tiene un formato inválido!`; break;
        case 'email': text = `"${this.aControles[error.control_name]}" tiene un formato inválido!`; break;
        // tslint:disable-next-line:max-line-length
        case 'minlength': text = `"${this.aControles[error.control_name]}" debe contener al menos ${error.error_value.requiredLength} caracteres!`; break;
        // tslint:disable-next-line:max-line-length
        case 'maxlength': text = `"${this.aControles[error.control_name]}" debe contener máximo ${error.error_value.requiredLength} caracteres!`; break;
        case 'areEqual': text = `"${this.aControles[error.control_name]}" es diferente!`; break;
        default: text = `Debes completar todos los campos.`;
      }
    }
    return text;
  }
}

export interface AllValidationErrors {
  control_name: string;
  error_name: string;
  error_value: any;
}

export interface FormGroupControls {
  [key: string]: AbstractControl;
}

export function getFormValidationErrors(controls: FormGroupControls): AllValidationErrors[] {
  console.log(controls);
  let errors: AllValidationErrors[] = [];
  Object.keys(controls).forEach(key => {
    const control = controls[ key ];
    if (control instanceof FormGroup) {
      errors = errors.concat(getFormValidationErrors(control.controls));
    }
    const controlErrors: ValidationErrors = controls[ key ].errors;
    if (controlErrors !== null) {
      Object.keys(controlErrors).forEach(keyError => {
        errors.push({
          control_name: key,
          error_name: keyError,
          error_value: controlErrors[ keyError ]
        });
      });
    }
  });
  return errors;
}
