import { Component} from '@angular/core';
import { setTheme } from 'ngx-bootstrap/utils';
declare const app_sidebar: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
  ) {
    setTheme('bs4'); // or 'bs4'
  }

}
