import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isEmpty'
})
export class IsEmptyPipe implements PipeTransform {

  transform(value: any): any {
    let bool = false;
    if (value === null || value === 'null') {
      bool = true;
      return true;
    }
    //Validar si es Undefined
    if (typeof value === 'undefined') {
      bool = true;
    }
    //Validar si la cadena está vacía
    if (typeof value === 'string' && value.length <= 0) {
      bool = true;
    }
    //Validar si el array tiene  items
    if (typeof value === 'object' && value.length === 0) {
      bool = true;
    }
    //Validar si el objeto tiene propiedades
    if (typeof value === 'object' && this.isHavePropertiesObject(value) === 0) {
        bool = true;
    }

    //validar si hay un numero válido
    if (typeof value === 'number' && isNaN(value)) {
        bool = true;
    }

    return bool;
  }

  isHavePropertiesObject(value){
    let count = 0;
    const properties = Object.keys(value);
    for (const item of properties) {
        count++;
    }
    return count;
  }


}
