import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgSelectConfig } from '@ng-select/ng-select';
import { ɵs } from '@ng-select/ng-select';
import { ValidatorPipe } from './global/pipes/validator.pipe';
//import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//import { ModalModule } from 'ngx-bootstrap/modal';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { IsEmptyPipe } from './global/pipes/is-empty.pipe';
import { NotifierModule, NotifierOptions, NotifierService } from 'angular-notifier';
const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 12
    },
    vertical: {
      position: 'top',
      distance: 12,
      gap: 10
    }
  },
  theme: 'material',
  behaviour: {
    autoHide: 1500,
    onClick: false,
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 100,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
      easing: 'ease'
    },
    overlap: 300
  }
  };
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,   
    AppRoutingModule,
    NgSelectModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    //BrowserAnimationsModule,
    ModalModule.forRoot(),
    NotifierModule.withConfig( customNotifierOptions )
  ],
  providers: [
    ValidatorPipe,
    NgSelectConfig,
    ɵs,
    BsModalService,
    IsEmptyPipe,
    NotifierService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
